<template>
  <div>
    <div class="breadcrumbs" data-aos="fade-in">
        <div class="container">
            <h2>{{$t("entry-details")}}</h2>
        </div>
    </div>
    <p>Hello</p>
  </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>